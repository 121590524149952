import { api } from "../config";

//auth
export const REGISTER_USER = "/register";
export const LOGIN_USER = "/login";
export const FORGOT_PASSWORD = "/forgot_password";
export const RESET_PASSWORD = "/reset_password";
export const VERIFY_OTP = "/verify_otp";
export const RESEND_OTP = "/resend_verification_code";
export const LOGIN_USER_WITH_FACEBOOK = "/fb_register";


//common
export const PROFILE_GET = "/profile";
export const PROFILE_UPDATE = "/update_profile";
export const PROFILE_UPDATE_KYC = "/update_kyc";
export const HOST_LIST_PRODUCT = "/product_list";
export const PROFILE_DETAILS = "/product_detail";
export const PAYMENT_CHECKOUT = "/payment_checkout";
export const LIST_PAYMENT = "/list_payment";
export const FINAL_PAYMENT_CHECKOUT = "/final_payment_checkout";
export const CANCEL_ORDER = "/cancel_order";

export const UPDATE_ORDER_STATUS = (
    orderId,
) => {
    let apiUrl = `/host/update_order_status/${orderId}`;
    return apiUrl;
};

export const UPDATE_ORDER_STATUS_SIGN_IN_RENTER_AGREEMENT = (
    orderId,
) => {
    let apiUrl = `/order/agreement?order_id=${orderId}`;
    return apiUrl;
};

export const POST_RENTER_UPDATE_STATUS = "/update_order_status/";
export const POST_SEND_MESSAGE = "/send_message";
export const GET_SITE_SETTING_DETAILS = "/site_settings";
export const POST_CONTACT_US = "/send_contact_message";
export const POST_GET_CITY = "/product_city_list";
export const CALCULATE_ORDER_PAYMENT = "/calculate_order_payment";

//renter
export const RENTER_LIST_ORDER = "/list_order";
export const RENTER_ORDER_DETAILS = "/order_detail";
export const RENTER_ADD_REVIEW_PRODUCT = "/add_review";

export const UPDATE_REVIEW_PRODUCT = (
    productId,
) => {
    let apiUrl = `/update_review/${productId}`;
    return apiUrl;
};

//*host
export const HOST_LIST_ORDER = "/host/list_order";
export const HOST_ORDER_DETAILS = "/host/order_detail";
export const HOST_ADD_REVIEW_PRODUCT = "/host/add_review";

export const HOST_UPDATE_REVIEW_PRODUCT = (
    productId,
) => {
    let apiUrl = `/host/update_review/${productId}`;
    return apiUrl;
};

// host_product_crud
export const LIST_HOST_PRODUCT = "/host/product_list";
export const ADD_LIST_HOST_PRODUCT = "/host/product_add";
export const GET_EDIT_HOST_PRODUCT = "/product_detail";
export const EDIT_IMAGE_HOST_PRODUCT = "/host/product_image_update";
export const EDIT_IMAGE_HOST_SIGNATURE = "/host/product_signature_update";
export const ADD_PROTECTION_PROOF = "/host/product_signature_protection_proof";
export const GET_PROTECTION_PROOF = "/host/get_product_signature_protection_proof";
export const UPDATE_PROTECTION_PROOF = "/host/product_signature_protection_proof_update";
export const DELETE_PROTECTION_PROOF = "/host/product_signature_protection_proof_delete";
export const DELETE_PROTECTION_PROOF_SINGLE = "/host/product_signature_protection_proof_delete_single";

export const EDIT_LIST_HOST_PRODUCT = (
    productId,
) => {
    let apiUrl = `/host/product_update/${productId}`;
    return apiUrl;
};

export const DELETE_LIST_HOST_PRODUCT = (
    productId,
) => {
    let apiUrl = `/host/product_soft_delete/${productId}`;
    return apiUrl;
};

//*promocode
export const PROMO_CODE_DETAILS = "/promocode_detail";
export const ADD_PROMO_CODE_HOST_RELATION = "/promocode/hostPromocodeRelation";

// chat service
export const CHAT_SERVICE = `${api.CHAT_SERVICE_URL}/chat`;


