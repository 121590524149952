import React, { useEffect, useState, useRef } from "react";
import RenterBannerslider from '../Common/Bannerslider'
import ProfileCard from '../Pages/Renter/ProfileCard';
import { State, City } from 'country-state-city';
import {
  ConvertResponseForCountry,
  ConvertToBinary,
  clearLocalStorage,
  ConvertResponseForStateOfCity
} from '../../helpers/commonfunction'
import { getStateByCode, getCityByName } from "../Common/getCountryStateCity";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from "react-redux";
import { getUserDetails, updateProfile } from "../../Redux/profile/action"
import { loginResetState } from "../../Redux/auth/login/action";
import UpdateDocConfirmationModal from "../Common/drivingLicenceModal";
import moment from "moment";
import { API_CODE } from '../../helpers/enum'
import { api } from '../../config';
import * as url from "../../helpers/url_helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import InputMask from 'react-input-mask';
import { useFormik } from "formik"
import * as Yup from "yup";
import stateJSON from '../../localJSONFiles/states.json'


export default function RenterProfile() {

  const ref = useRef()
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const datePickerRef = useRef(null);

  //*State
  const [showEnableModal, setEnableShowModal] = useState(false);
  const [docSelectError, setDocSelectError] = useState(false);
  const [isShowLoader, setIsShowLoader] = useState(false);

  //*List
  const [countryArray] = useState([{ "label": "United States", "value": "US" }]);
  const [stateArray, setStateArray] = useState([]);
  const [cityArray, setCityArray] = useState([]);
  const [docList, setDocList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNo: "",
    countryValue: null,
    stateValue: null,
    city: "",
    address: "",
    zip_code: "",
    dateOfBirth: null,
  }
  const updateProfileSchema = Yup.object({
    firstName: Yup.string().min(2, "First name must be at least 2 characters.").max(30).required('Please enter first name.'),
    lastName: Yup.string().min(2, "Last name must be at least 2 characters.").max(30).required('Please enter last name.'),
    email: Yup.string()
      .test('validEmail', 'Please enter a valid email', function (email) {
        const phoneNo = this.parent.phoneNo;
        if (!email && (!phoneNo || phoneNo === "1")) {
          return this.createError({ message: "Please enter a valid email or a valid phone number." });
        }
        // Use a regular expression to validate the email address
        if (email) {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          if (!email.match(emailRegex)) {
            return this.createError({ message: "Please enter a valid email address." });
          }
        }
        return true;
      }),
    phoneNo: Yup.string()
      .test('validEmail', 'Please enter a valid email', function (phoneNo) {
        const email = this.parent.email;
        if (!email && (!phoneNo || phoneNo === "1")) {
          return this.createError({ message: "Please enter a valid email or a valid phone number." });
        }
        return true;
      }),
    // countryValue: Yup.object().required('Please select country.'),
    stateValue: Yup.object().required('Please select state.'),
    city: Yup.string().required('Please Enter city'),
    zip_code: Yup.string().min(5).max(5).required('Please Enter ZIP Code'),
    address: Yup.string().min(2, "Address must be at least 2 characters.").max(100).required('Please enter address.'),
    dateOfBirth: Yup.string().default(() => new Date()).required('Please select date of birth.'),
  })

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isSubmitting, resetForm, setFieldValue, } = useFormik({
    initialValues: initialValues,
    validationSchema: updateProfileSchema,
    onSubmit: (values) => {
      onClickUpdateUser();
    },
  })

  const user = useSelector(state => state?.UserProfile?.user);
  const success = useSelector(state => state?.UserProfile?.success);
  const loading = useSelector(state => state?.UserProfile?.loading);
  const isUpdateSuccess = useSelector(state => state?.UserProfile?.isUpdateSuccess);
  const isUpdateError = useSelector(state => state?.UserProfile?.isUpdateError);
  const isUpdateSuccessMsg = useSelector(state => state?.UserProfile?.isUpdateSuccessMsg);
  const isUpdateErrorMsg = useSelector(state => state?.UserProfile?.isUpdateErrorMsg);
  const error = useSelector(state => state?.UserProfile?.error);
  const errorMessage = useSelector(state => state?.UserProfile?.errorMessage);

  useEffect(() => {
    //_________________old Code_______________________//
    // let stateArrayResponse = State.getStatesOfCountry("US");
    // let finalStateArray = ConvertResponseForCountry(stateArrayResponse);
    //_________________old Code_______________________//
    let finalStateArray = stateJSON;
    setStateArray(finalStateArray);
  }, []);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [isUpdateSuccess, isUpdateError]);


  useEffect(() => {
    if (isUpdateSuccess && !loading) {
      toast.success(isUpdateSuccessMsg);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    toast.error(isUpdateErrorMsg);
  }, [isUpdateError]);

  useEffect(() => {
    if (success && !loading) {
      if (user) {
        getExistingData();
      }
    }
  }, [success, loading]);

  //*expire token
  useEffect(() => {
    if ((error && !loading)) {
      if (errorMessage === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [error, errorMessage]);

  //*expire token
  useEffect(() => {
    if ((isUpdateErrorMsg && !loading)) {
      if (isUpdateError === "Request failed with status code 401") {
        navigation("/login");
        clearLocalStorage();
        toast.error('JWT Expire.');
      }
    }
  }, [isUpdateError, isUpdateErrorMsg]);


  //*
  const getExistingData = async () => {
    setFieldValue("firstName", user?.login_user?.first_name);
    setFieldValue("lastName", user?.login_user?.last_name);
    setFieldValue("email", user?.login_user?.email);
    setFieldValue("phoneNo", user?.login_user?.phone_number);
    setFieldValue("address", user?.login_user?.address);
    setFieldValue("countryValue", { "label": "United States", "value": "US" });

    if (user?.login_user?.state !== undefined && user?.login_user?.state !== null) {
      let getStateValue = await getStateByCode(user?.login_user?.state);
      
      if (getStateValue) {
        setFieldValue("stateValue", getStateValue);
      }
    }
    
    setFieldValue("city", user?.login_user?.city);
    setFieldValue("zip_code", user?.login_user?.zip_code);
    setFieldValue("dateOfBirth", moment(user?.login_user?.dob).format("MM/DD/YYYY"));
  }

  //*get city
  const onClickState = (e) => {
    setFieldValue("city", null);
    let stateValue = e.value
    let cityArrayResponse = City.getCitiesOfState("US", stateValue);
    let finalCityArray = ConvertResponseForStateOfCity(cityArrayResponse);
    setCityArray(finalCityArray);
  }


  //*Update User Profile
  const onClickUpdateUser = () => {

    const formattedDateOfBirth = moment(values?.dateOfBirth, 'MM/DD/YYYY').format('YYYY-MM-DD');
    let dataObj = {
      "email": values?.email,
      "first_name": values?.firstName,
      "last_name": values?.lastName,
      "role": user?.login_user?.role,
      "address": values?.address,
      "city": values?.city,
      "zip_code": values.zip_code,
      "state": values?.stateValue?.value,
      "country": values?.countryValue?.label,
      "dob": formattedDateOfBirth,
      "phone_number": values?.phoneNo === "" ? "" : "+" + values?.phoneNo,
    }
    dispatch(updateProfile(dataObj));
  };

  //*Clear State
  const onClickClearState = () => {
    resetForm(); setDocSelectError(false);
    setDocList([]); setFileList([]);
  };

  //*Update Doc enable popup
  const onClickUpdateDoc = () => {
    if (docList?.length === 0) { setDocSelectError(true); return false; } else { setDocSelectError(false); }
    setEnableShowModal(true)
  };

  //*Update Doc
  const onClickUpdateDocPopup = () => {

    setEnableShowModal(true);
    setIsShowLoader(true);

    let dataAppend = new FormData();
    //append images
    for (let [key, itm] of Object.entries(fileList)) {
      dataAppend.append('identity_doc', itm, itm?.name);
    }
    let token = localStorage.getItem("accessToken");
    let headers = {};
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    var requestOptions = {
      method: 'POST',
      headers,
      body: dataAppend,
      redirect: 'follow'
    };

    fetch(`${api.API_URL}${url?.PROFILE_UPDATE_KYC}`, requestOptions)
      .then(response => response.json())
      .then((result) => {
        //need to redirect to login page
        if (result?.statusCode === API_CODE.SUCCESS) {
          setIsShowLoader(false);
          dispatch(getUserDetails());
          toast.success('Update successfully.');
          setDocList([]); setFileList([]);
          dispatch(loginResetState());
          navigation("/login");
        }
        else if (result?.statusCode === 422) {
          if (result.message === "jwt expired") {
            navigation("/login");
            clearLocalStorage();
            // toast.error('JWT Expire.');
          }
        }
      })
      .catch(error => console.log('error', error));
  };

  //*Select Doc
  async function selectDocuments(e) {
    let file = e.target.files;
    let allowedFileTypes = [".jpg", ".jpeg", ".png", ".webp", "image/jpeg", "image/jpg", "image/png", "image/webp"];
    // console.log(file)
    let docFinalList = [];
    if (docList.length > 0) {
      for (let i = 0; i < file.length; i++) {
        let fileType = file[i].type;
        let binary = await ConvertToBinary(file[i]);
        if (allowedFileTypes.includes(fileType)) {
          let data = {
            "file": binary,
            "filename": file[i].name
          }
          docFinalList.push(data);
          if (i === file.length - 1) {
            let finalData = [...docFinalList]
            setDocList([...docList, finalData])
            setFileList(file);
            setDocSelectError(false);
          }
        }
      }
    }
    else {
      for (let i = 0; i < file.length; i++) {
        let fileType = file[i].type;
        let binary = await ConvertToBinary(file[i]);
        if (allowedFileTypes.includes(fileType)) {
          let data = {
            "file": binary,
            "filename": file[i].name
          }
          docFinalList.push(data);
          if (i === file.length - 1) {
            let finalData = [...docFinalList]
            setDocList(finalData)
            setFileList(file);
            setDocSelectError(false);
          }
        }
      }
    }
  }

  const handleClick = (e) => {
    ref.current.click()
  }

  //*onClick Remove Doc
  const onClickRemoveDocuments = (index) => {
    let data = [...docList];
    data.splice(index, 1)
    setDocList(data)
  }

  //DOB  
  const handleCalendarIconPickClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  // date handle chage
  const handleDateChange = (date) => {
    if (date) {
      setFieldValue("dateOfBirth", date);
    } else {
      setFieldValue("dateOfBirth", null);
    }
  };

  return (
    <div>
      <RenterBannerslider
        headerName={"Profile"}
      />
      <section className="profile-wrapper profile-btns py32-wrapper px-18">
        {isShowLoader === true &&
          <div id="loader" data-wordload="Please Wait"></div>
        }
        <div className="container-fluid">
          <div className="row g-4">
            <ProfileCard />
            <div className="col-md-9 col-sm-8 profile-main">
              <div className="card ms-xl-1">
                <div className="card-header">
                  <h3>PROFILE</h3>
                </div>
                <div className="card-body">
                  <form action="#">
                    <div className="row gy-md-4 g-3">
                      <div className="col-sm-6">
                        <label htmlFor="fname">FIRST NAME <label style={{ color: '#FF0000' }}>* </label></label>
                        <input
                          maxLength={30}
                          name="firstName"
                          value={values.firstName}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                            setFieldValue("firstName", sanitizedInputValue);
                          }}
                          onBlur={handleBlur}
                          type="text"
                          id="fname"
                          className={errors.firstName && touched.firstName ? 'form-control form-control-error' : 'form-control'}
                          placeholder="First name" />
                        {errors.firstName && touched.firstName ? <p className="error-text">{errors.firstName}</p> : null}
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="lname">LAST NAME <label style={{ color: '#FF0000' }}>* </label></label>
                        <input
                          maxLength={30}
                          name="lastName"
                          value={values.lastName}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                            setFieldValue("lastName", sanitizedInputValue);
                          }}
                          onBlur={handleBlur}
                          type="text"
                          id="lname"
                          className={errors.lastName && touched.lastName ? 'form-control form-control-error' : 'form-control'}
                          placeholder="Last name" />
                        {errors.lastName && touched.lastName ? <p className="error-text">{errors.lastName}</p> : null}
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="email">EMAIL <label style={{ color: '#FF0000' }}>* </label></label>
                        <input
                          name="email"
                          value={values.email}
                          maxLength={30}
                          onChange={(e) => {
                            setFieldValue("email", e.target.value);
                          }}
                          onBlur={handleBlur}
                          type="email"
                          id="email"
                          className={errors.email && touched.email ? 'form-control form-control-error' : 'form-control'}
                          placeholder="Email"
                        />
                        {errors.email && touched.email ? <p className="error-text">{errors.email}</p> : null}
                      </div>
                      <div className="col-sm-6">
                        <label htmlFor="phone">PHONE </label>
                        <PhoneInput
                          name="phoneNo"
                          enableAreaCodes={false}
                          country={'us'}
                          onlyCountries={['us']}
                          value={values.phoneNo}
                          onChange={(phone) =>
                            setFieldValue("phoneNo", phone)
                          }
                          disableDropdown={true}
                          countryCodeEditable={false}
                          className={errors.phoneNo && touched.phoneNo ? 'register-phone form-control-error' : 'register-phone'}
                        >
                        </PhoneInput>
                        {errors.phoneNo && touched.phoneNo ? <p className="error-text">{errors.phoneNo}</p> : null}
                      </div>

                      <div className="col-sm-6">
                          <label htmlFor="state">State <label style={{ color: '#FF0000' }}>* </label></label>
                          <Select
                            options={stateArray}
                            name="stateValue"
                            value={values.stateValue}
                            onChange={(e) => {
                              setFieldValue("stateValue", e);
                              onClickState(e);
                            }}
                            onBlur={handleBlur}
                            className={errors.stateValue && touched.stateValue ? 'form-select form-control-error' : 'form-select'}
                            placeholder={"Select state"}
                            classNamePrefix="my-react-select"
                            menuPortalTarget={document.body}
                            menuPosition={'fixed'}
                          />
                          {errors.stateValue && touched.stateValue ? <p className="error-text">{errors.stateValue}</p> : null}
                        </div>

                        <div className="col-sm-6">
                          <label htmlFor="city">CITY <label style={{ color: '#FF0000' }}>* </label></label>
                          <input maxLength={30} name="city" value={values.city}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const sanitizedInputValue = inputValue.replace(/[^a-zA-Z0-9\s]/g, '');
                              setFieldValue("city", sanitizedInputValue);
                            }}
                            onBlur={handleBlur}
                            type="text"
                            id="city"
                            className={errors.city && touched.city ? 'form-control form-control-error' : 'form-control'}
                            placeholder="Washington"
                          />
                          {errors.city && touched.city ? <p className="error-text">{errors.city}</p> : null}
                        </div>

                      <div className="col-sm-6">
                        <label htmlFor="address">ADDRESS <label style={{ color: '#FF0000' }}>* </label></label>
                        <input name="address" value={values.address} maxLength={100}
                          onChange={(e) => {
                            setFieldValue("address", e.target.value);
                          }}
                          onBlur={handleBlur}
                          type="text"
                          id="address"
                          className={errors.address && touched.address ? 'form-control form-control-error' : 'form-control'}
                          placeholder="Address" />
                        {errors.address && touched.address ? <p className="error-text">{errors.address}</p> : null}
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="dateOfBirth">Date Of Birth<label style={{ color: '#FF0000' }}>* </label></label>
                        <div className="input-group">
                          <DatePicker
                            selected={values.dateOfBirth ? moment(values.dateOfBirth, 'MM/DD/YYYY').toDate() : null}
                            onChange={handleDateChange}
                            dateFormat="MM/dd/yyyy"
                            id="dob_date"
                            className={errors.dateOfBirth && touched.dateOfBirth ? 'form-control form-control-error' : 'form-control'}
                            placeholderText="MM/DD/YYYY"
                            maxDate={new Date()}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            scrollableYearDropdown
                            yearDropdownItemNumber={90}
                            ref={datePickerRef}
                            customInput={
                              <InputMask
                                mask="99/99/9999"
                                value={values.dateOfBirth}
                                onChange={e => {
                                  const inputValue = e.target.value;
                                  values.dateOfBirth = inputValue;
                                }}
                                placeholder="MM/DD/YYYY"
                              />
                            }
                          />
                          <div className="input-group-append position-absolute profile-date-picker">
                            <span
                              onClick={handleCalendarIconPickClick}
                              style={{ cursor: "pointer" }}
                            >
                              <svg
                                fill="#000000"
                                width="28px"
                                height="28px"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z" />
                                <path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z" />
                              </svg>
                            </span>
                          </div>
                        </div>
                        {errors.dateOfBirth && touched.dateOfBirth ? <p className="error-text">{errors.dateOfBirth}</p> : null}
                      </div>

                      <div className="col-sm-6">
                        <label htmlFor="zip_code">ZIP CODE <label style={{ color: '#FF0000' }}>* </label> </label>
                        <input name="zip_code" value={values.zip_code} maxLength={100}
                          onChange={(e) => {
                            setFieldValue("zip_code", e.target.value);
                          }}
                          onBlur={handleBlur}
                          type="text"
                          id="zip_code"
                          className={errors.zip_code && touched.zip_code ? 'form-control form-control-error' : 'form-control'}
                          placeholder="23213" />
                        {errors.zip_code && touched.zip_code ? <p className="error-text">{errors.zip_code}</p> : null}
                      </div>

                      <div>
                        <button type="button" onClick={() => {
                          handleSubmit();
                        }} className="btn btn-green"><span>Update Profile Edits</span></button>
                        <Link
                          onClick={() => {
                            onClickClearState();
                          }}
                          className="btn btn-black"><span>CANCEL</span></Link>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
              <div className="card ms-xl-1 mt-4">
                <div className="card-header">
                  <h3>Drivers license</h3>
                  <a data-toggle="tooltip" data-placement="right" title="By providing your driver's license, we are able to verify the identity of all our members to ensure a safe experience for everyone.">
                    <i className="fas fa-info-circle"></i>
                  </a>
                </div>

                <div className="card-body">
                  <form action="#">
                    <div className="row gy-md-4 g-3">
                      <div>
                        <Link onClick={() => {
                          handleClick();
                        }}
                          className={docSelectError ? "btn btn-green form-control-error" : "btn btn-green"}
                        ><span>Browse</span></Link>
                        <input ref={ref} type="file" accept=".jpg, .jpeg, .png, .webp" onChange={(e) => { selectDocuments(e) }} multiple="multiple" style={{ display: 'none' }} />
                        {docSelectError && <p className="error-text">{"Please Select atleast one document."}</p>}
                      </div>
                      {
                        docList.length > 0 &&
                        docList.map((item, index) => {
                          return (
                            <div key={index} className="card-header display: flex  justify-content: space-between">
                              {item.filename.length > 15 ? (
                                <h3 className="docs-wrap">{item.filename}</h3>
                              ) : (
                                <h3>{item.filename}</h3>
                              )}
                              <li><Link onClick={() => {
                                onClickRemoveDocuments(index);
                              }} className="active">Delete</Link></li>
                            </div>
                          )
                        })
                      }
                    </div>
                    <hr></hr>
                    <div>
                      <Link onClick={() => {
                        onClickUpdateDoc();
                      }} className="btn btn-green my-2"><span>Update Document Edits</span></Link>
                      <Link
                        onClick={() => {
                          setDocSelectError(false);
                          setDocList([]); setFileList([]);
                        }}
                        className="btn btn-black"><span>CANCEL</span></Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateDocConfirmationModal
        isOpen={showEnableModal}
        onClose={() => setEnableShowModal(false)}
        onUpdate={() => {
          onClickUpdateDocPopup();
        }}
      />
    </div>
  )
}